import React from 'react'
import { Text } from '../design-system'
import { ProtectedContent, TWTCorePage, LoginScreen } from '../twt-core'

const LoginPage = () => (
  <TWTCorePage>
    <ProtectedContent
      loggedOutContent={
        <LoginScreen onCompleted={() => console.log('login completed')} />
      }
    >
      <Text>You are logged in!</Text>
    </ProtectedContent>
  </TWTCorePage>
)

export default LoginPage
